import React from 'react';
import './ListItems.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlipMove from 'react-flip-move';


function ListItems(props: any) {
  const items: any = props.items;
  const listItems = items.map((item: any) =>

    <div className="list" key={item.key}>
      <p>
        <input
        type="text"
        id={item.key}
        onChange = { (e) => {props.setUpdate(e.target.value, item.key)}}
        value={item.text}/>

        <span>
          <FontAwesomeIcon className="faicons" icon="trash"
          onClick = { () => props.deleteItem(item.key)}/>
        </span>
      </p>
    </div>
  )

  return(
  <div>
    <FlipMove duration={300} easing="ease-in-out">
    {listItems}
    </FlipMove>
  </div>
  )

}
export default ListItems;