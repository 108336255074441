import React, { useState }  from 'react';
import logo from './logo.svg';
import './App.css';
import ListItems from './ListItems'
import { AnyCnameRecord } from 'dns';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import { isTemplateExpression } from 'typescript';

library.add(faTrash);

function App() {
  const [items, setItems]: any = useState([])
  const [currentItem, setCurrentItem]: any = useState({text:"", key:""})

  // This adds new item (input) to array of items when submit is clicked
  function addItem(e: any) {
    e.preventDefault(); // This prevents browser refresh when submit is clicked
    console.log(currentItem)
    if (currentItem.text !== "") {
      const newItems: any = [...items, currentItem];
      setItems(newItems)
      setCurrentItem({text: "", key: ""})
      }
    }
  
  function handleInput(e: any) {
    setCurrentItem({text: e.target.value, key: Date.now()})
  }

  // This filters array of items and returns a new array where the filtered key doesnt exist "item.key !== key" that way the key is deleted from the new array
  function deleteItem(key: any) {
    const filteredItems = items.filter((item: any) => item.key !== key);
      setItems(filteredItems)
  }

  // This updates the items array component state every time note is edited
  const setUpdate = (text: any, key: any): any => {
    items.map((item: any) => {
      if (item.key === key) {item.text = text}
    })
    setItems(items)
    setCurrentItem({text: "", key: ""}) // Without this line child component "ListItems" doesn update or re-render (editing doesn't work). And I don't know why?
  }

  return (
    <div>
      <h1 id="title">THE NOTE APP</h1>
    <div className="App">
      <header>
      
      <form id="notes-form" onSubmit={addItem}>
        <input
        type="text"
        placeholder="Enter note"
        value={currentItem.text}
        onChange={handleInput}
        />
        <button type="submit">Add</button>
      </form>

        <ListItems setUpdate={setUpdate} items={items} deleteItem={deleteItem} />

      </header>
    </div>
    </div>
  );

}


export default App;
